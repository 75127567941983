<template>
  <component
    :is="component"
    v-if="component"
    :data.sync="data"
    :type="type"
    :name="type"
    @send="send" />
</template>
<script>
  export default {
    props: ['data', 'type'],
    computed: {
      component () {
        let type = this.type
        return () => import(`@/components/chat/ui/${type}`)
      }
    },
    methods: {
      send (option) {
        this.$emit('send', option)
      }
    }
  }
</script>
