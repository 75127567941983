<template>
  <v-speed-dial
    v-model="fab"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :direction="direction"
    :open-on-hover="hover"
    :transition="transition">
    <template v-slot:activator="{ on }">
      <v-btn
        v-model="fab"
        color="darkGray"
        dark
        fab
        small
        v-on="on">
        <v-icon>{{ $root.icons.layout.help }}</v-icon>
        <v-icon>{{ $root.icons.action.close }}</v-icon>
      </v-btn>
    </template>
    <v-btn
      v-for="(menu, index) in menuList"
      :key="index"
      :color="menu.color"
      dark
      small>
      {{ menu.label }}
    </v-btn>
  </v-speed-dial>
</template>

<script>
  export default {
    data: () => ({
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      menuList: [
        {
          label: 'Dúvidas sobre a proposta ?',
          color: 'channelEmail'
        },
        {
          label: 'O que é a Modera ?',
          color: 'channelSms'
        },
        {
          label: 'Tem outra dúvida ?',
          color: 'channelEmail'
        }
      ]
    }),
    mounted () {
      this.setPositionMenu()
    },
    methods: {
      setPositionMenu () {
        this.direction = this.$vuetify.breakpoint.xsOnly ? 'bottom' : 'top'
      }
    }
  }
</script>

<style lang="sass">
  .speed-dial
    position: fixed
    bottom: 5px
    right: 5px
    z-index: 2

  .btn--floating
    position: relative

    &.btn--small
      width: 30px
      height: 30px

  .speed-dial__list
    align-items: flex-end

  @media screen and (max-width: $max-tablet)
    .speed-dial__list
      padding-right: 20px


    .speed-dial--bottom:not(.speed-dial--absolute)
      bottom: auto
      right: 0
      top: 60px

</style>
