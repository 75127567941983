const proposal = {
  '__typename': 'Proposal',
  'id': '0b688443-d3b2-486c-bd43-48470caffdc4',
  'code': 'PROP-2018-0000007',
  'account': {
    'document': '297.026.848-54',
    '__typename': 'Account'
  },
  'case': {
    'recipient': {
      'name': 'DEIVID MARQUES GONCALVES',
      'email': 'dvdmarques@terra.com.br',
      'phones': [{
        'type': 'mobile',
        'number': '986775728',
        '__typename': 'Phone'
      }],
      'address': {
        'address': 'R ITAPAIUNA',
        'number': 2434,
        'complement': '',
        'neighborhood': 'JARDIM MORUMBI',
        'city': 'SAO PAULO',
        'uf': 'SP',
        'zipCode': '05707001',
        '__typename': 'Address'
      },
      '__typename': 'Recipient'
    },
    '__typename': 'Case'
  },
  'company': {
    'fantasyName': 'SENAC -AR/DF',
    'cnpj': '03.296.968/0001-03',
    'stateRegistration': '123',
    'website': 'www.senacdf.com.br',
    'email': 'luciana.aragao@modera.app',
    'address': [{
      'address': 'TR 3',
      'number': 625,
      'complement': '695 COBERTURA C # LOTES#',
      'city': 'BRASILIA',
      'uf': 'DF',
      'zipCode': '71200030',
      '__typename': 'Address'
    }],
    '__typename': 'Company'
  },
  'details': [{
    'basis': 1000,
    'type': 'correction_index',
    'discount': 0,
    'value': 0,
    '__typename': 'TicketDetail'
  }, {
    'basis': 1052.3,
    'type': 'fees',
    'discount': 0,
    'value': 105.23,
    '__typename': 'TicketDetail'
  }, {
    'basis': 1000,
    'type': 'fine',
    'discount': 0,
    'value': 20,
    '__typename': 'TicketDetail'
  }, {
    'basis': 1020,
    'type': 'interest_rate',
    'discount': 0,
    'value': 32.3,
    '__typename': 'TicketDetail'
  }],
  'tickets': [{
    'originalValue': 2000,
    'currentValue': 1157.53,
    'dueAt': null,
    'details': [{
      'basis': 1000,
      'type': 'fine',
      'percent': 2,
      'value': 20,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1000,
      'type': 'correction_index',
      'percent': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1020,
      'type': 'interest_rate',
      'percent': 3.17,
      'value': 32.3,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1052.3,
      'type': 'fees',
      'percent': 10,
      'value': 105.23,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Ticket'
  },
  {
    'originalValue': 2000,
    'currentValue': 1157.53,
    'dueAt': null,
    'details': [{
      'basis': 1000,
      'type': 'fine',
      'percent': 2,
      'value': 20,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1000,
      'type': 'correction_index',
      'percent': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1020,
      'type': 'interest_rate',
      'percent': 3.17,
      'value': 32.3,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1052.3,
      'type': 'fees',
      'percent': 10,
      'value': 105.23,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Ticket'
  },
  {
    'originalValue': 2000,
    'currentValue': 1157.53,
    'dueAt': null,
    'details': [{
      'basis': 1000,
      'type': 'fine',
      'percent': 2,
      'value': 20,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1000,
      'type': 'correction_index',
      'percent': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1020,
      'type': 'interest_rate',
      'percent': 3.17,
      'value': 32.3,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1052.3,
      'type': 'fees',
      'percent': 10,
      'value': 105.23,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Ticket'
  },
  {
    'originalValue': 2000,
    'currentValue': 1157.53,
    'dueAt': null,
    'details': [{
      'basis': 1000,
      'type': 'fine',
      'percent': 2,
      'value': 20,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1000,
      'type': 'correction_index',
      'percent': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1020,
      'type': 'interest_rate',
      'percent': 3.17,
      'value': 32.3,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1052.3,
      'type': 'fees',
      'percent': 10,
      'value': 105.23,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Ticket'
  },
  {
    'originalValue': 2000,
    'currentValue': 1157.53,
    'dueAt': null,
    'details': [{
      'basis': 1000,
      'type': 'fine',
      'percent': 2,
      'value': 20,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1000,
      'type': 'correction_index',
      'percent': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1020,
      'type': 'interest_rate',
      'percent': 3.17,
      'value': 32.3,
      '__typename': 'TicketDetail'
    }, {
      'basis': 1052.3,
      'type': 'fees',
      'percent': 10,
      'value': 105.23,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Ticket'
  }],
  'installmentSelected': {
    'originalValue': '1000',
    'currentValue': '1100',
    'parcel': 1,
    'parcelValue': '1100',
    'details': [{
      'type': 'correction_index',
      'basis': 1000,
      'percent': 0,
      'discount': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fees',
      'basis': 1000,
      'percent': 10,
      'discount': 0,
      'value': 100,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fine',
      'basis': 1000,
      'percent': 2,
      'discount': 20,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'type': 'interest_rate',
      'basis': 1000,
      'percent': 3.17,
      'discount': 31.67,
      'value': 0,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Installment'
  },
  'installments': [{
    'originalValue': '1000',
    'currentValue': '1100',
    'parcel': 1,
    'parcelValue': '1100',
    'details': [{
      'type': 'correction_index',
      'basis': 1000,
      'percent': 0,
      'discount': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fees',
      'basis': 1000,
      'percent': 10,
      'discount': 0,
      'value': 100,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fine',
      'basis': 1000,
      'percent': 2,
      'discount': 20,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'type': 'interest_rate',
      'basis': 1000,
      'percent': 3.17,
      'discount': 31.67,
      'value': 0,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Installment'
  }, {
    'originalValue': '1000',
    'currentValue': '1102.75',
    'parcel': 2,
    'parcelValue': '551.38',
    'details': [{
      'type': 'correction_index',
      'basis': 1000,
      'percent': 0,
      'discount': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fees',
      'basis': 1050.24,
      'percent': 10,
      'discount': 52.512,
      'value': 52.512,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fine',
      'basis': 1000,
      'percent': 2,
      'discount': 2,
      'value': 18,
      '__typename': 'TicketDetail'
    }, {
      'type': 'interest_rate',
      'basis': 1018,
      'percent': 3.17,
      'discount': 0,
      'value': 32.24,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Installment'
  }, {
    'originalValue': '1000',
    'currentValue': '1102.75',
    'parcel': 3,
    'parcelValue': '367.58',
    'details': [{
      'type': 'correction_index',
      'basis': 1000,
      'percent': 0,
      'discount': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fees',
      'basis': 1050.24,
      'percent': 10,
      'discount': 52.512,
      'value': 52.512,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fine',
      'basis': 1000,
      'percent': 2,
      'discount': 2,
      'value': 18,
      '__typename': 'TicketDetail'
    }, {
      'type': 'interest_rate',
      'basis': 1018,
      'percent': 3.17,
      'discount': 0,
      'value': 32.24,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Installment'
  }, {
    'originalValue': '1000',
    'currentValue': '1102.75',
    'parcel': 4,
    'parcelValue': '275.69',
    'details': [{
      'type': 'correction_index',
      'basis': 1000,
      'percent': 0,
      'discount': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fees',
      'basis': 1050.24,
      'percent': 10,
      'discount': 52.512,
      'value': 52.512,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fine',
      'basis': 1000,
      'percent': 2,
      'discount': 2,
      'value': 18,
      '__typename': 'TicketDetail'
    }, {
      'type': 'interest_rate',
      'basis': 1018,
      'percent': 3.17,
      'discount': 0,
      'value': 32.24,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Installment'
  }, {
    'originalValue': '1000',
    'currentValue': '1102.75',
    'parcel': 5,
    'parcelValue': '220.55',
    'details': [{
      'type': 'correction_index',
      'basis': 1000,
      'percent': 0,
      'discount': 0,
      'value': 0,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fees',
      'basis': 1050.24,
      'percent': 10,
      'discount': 52.512,
      'value': 52.512,
      '__typename': 'TicketDetail'
    }, {
      'type': 'fine',
      'basis': 1000,
      'percent': 2,
      'discount': 2,
      'value': 18,
      '__typename': 'TicketDetail'
    }, {
      'type': 'interest_rate',
      'basis': 1018,
      'percent': 3.17,
      'discount': 0,
      'value': 32.24,
      '__typename': 'TicketDetail'
    }],
    '__typename': 'Installment'
  }],
  'originalValue': '1000',
  'currentValue': '1157.53',
  'state': 'created',
  'daysExpire': 90,
  'percentExpire': 1,
  'insertedAt': '09/11/2018 12:13:43',
  'updatedAt': '09/11/2018 12:13:43'
}
export default proposal
