<template>
  <component
    :is="component"
    v-if="component"
    :data.sync="dataTransform"
    :type="type" />
</template>
<script>
  export default {
    props: ['data', 'type'],
    data () {
      return {
        component: null
      }
    },
    computed: {
      loader () {
        let type = this.type
        if (!type) {
          return null
        }
        return () => import(`@/components/chat/ui/${type}`)
      },
      dataTransform () {
        return Object.assign({}, this.data, { input: false })
      }
    },
    mounted () {
      this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import(`@/components/chat/ui/Message`)
        })
    }
  }
</script>
