<template>
  <div class="expire-info">
    <span class="prefix-label">Expira em</span>
    <span class="day">10</span>
    <span class="suffix-label">dias</span>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="sass" scoped>
  .expire-info
    display: flex
    color: #fff
    align-items: flex-end
    height: inherit

    .day
      font-size: 40px
      font-weight: 500
      margin: 0 5px
      // line-height: 1.7
      align-self: end

  .prefix-label
    // flex-basis: 30px
    line-height: 1
    text-align: right

  .prefix-label,
  .suffix-label
    font-size: 12px
</style>
